<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <div class="config-wrapper">
        <div class="config-header">
          <span>{{ $t('homeConfigDashboard.meterConfig') }}</span>
          <el-button type="primary" size="medium" icon="el-icon-add-solid" @click="openMeterDialog">{{ $t('common.add') }}</el-button>
        </div>
        <div class="config-content">
          <el-table :data="meterTableData" style="width: 100%">
            <el-table-column prop="cmdesc" :label="$t('monitorTemplateCreate.monitoringName')"> </el-table-column>
            <el-table-column prop="label" :label="$t('homeConfigDashboard.meters')"> </el-table-column>
            <el-table-column :label="$t('common.operation')" width="70">
              <template slot-scope="{ row }">
                <el-tooltip effect="light" :content="$t('common.delete')" placement="top">
                  <el-button @click="deleteMeterClick(row)" type="text" icon="el-icon-delete"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <pagination-box :total="meterTablePageObj.total" :currentPage="meterTablePageObj.page" :pageSize="meterTablePageObj.size" @current-change="getPaginationMeterList"></pagination-box>

          <div class="number-box">
            <i18n path="homeConfigDashboard.refreshRate" :tag="false">
              <template slot="number">
                <el-input-number :step-strictly="true" style="width: 100px" v-model="refreshrateDashboard" controls-position="right" :min="0"></el-input-number>
              </template>
            </i18n>
            <span class="tips">{{ $t('homeConfigDashboard.noRefresh') }}</span>
          </div>
        </div>
      </div>

      <div class="config-wrapper">
        <div class="config-header">
          {{ $t('homeConfigDashboard.chartConfig') }}
        </div>
        <div class="config-content">
          <el-checkbox-group v-model="selectChartShowData">
            <div v-for="(chartitem, chartindex) in chartsyslist" :key="chartindex">
              <el-checkbox :label="chartitem.type">{{ getChartDescription(chartitem.type) }}</el-checkbox>
            </div>
          </el-checkbox-group>

          <div class="input-box">
            {{ $t('homeConfigDashboard.chartDisplayTime') }}：
            <el-select v-model="chartShowTime">
              <el-option value="1" :label="$t('homeConfigDashboard.nearlySevenDays')"></el-option>
              <el-option value="2" :label="$t('homeConfigDashboard.nearlyThirtyDays')"></el-option>
              <el-option value="3" :label="$t('homeConfigDashboard.nearlyNinetyDays')"></el-option>
              <el-option value="4" :label="$t('homeConfigDashboard.thisMonth')"></el-option>
              <el-option value="5" :label="$t('homeConfigDashboard.thisQuarter')"></el-option>
              <el-option value="6" :label="$t('homeConfigDashboard.lastQuarter')"></el-option>
              <el-option value="7" :label="$t('homeConfigDashboard.thisYear')"></el-option>
              <el-option value="8" :label="$t('homeConfigDashboard.prevYear')"></el-option>
            </el-select>
          </div>
          <div class="number-box">
            <i18n path="homeConfigDashboard.refreshRate" :tag="false">
              <template slot="number">
                <el-input-number :step-strictly="true" style="width: 100px" v-model="refreshrateChart" controls-position="right" :min="0"></el-input-number>
              </template>
            </i18n>
            <span class="tips">{{ $t('homeConfigDashboard.noRefresh') }}</span>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer class="footer-wrapper" height="70px">
      <el-button @click="cancelConfigClick">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="saveConfigClick()">{{ $t('common.save') }}</el-button>
    </el-footer>
    <el-dialog :visible.sync="meterDialogVisible" width="600px" :title="$t('homeConfigDashboard.addMeter')" append-to-body custom-class="meter-dialog" :before-close="closeMeterDialog" top="8vh">
      <div class="diaolg-body" ref="meterDialogBody">
        <div class="search-box">
          <el-input :placeholder="$t('homeConfigDashboard.meterSearch')" :title="meterQuery ? '' : $t('homeConfigDashboard.meterSearch')" v-model="meterQuery" @keyup.enter.native="getcmdashboard" clearable>
            <el-button icon="el-icon-search" @click="getcmdashboard" slot="append"></el-button>
          </el-input>
        </div>
        <div class="meter-content">
          <el-tree :data="meterTreeList" show-checkbox node-key="id" ref="meterTree" highlight-current
                   icon-class="el-icon-nextPage" :default-expanded-keys="meterExpandList" @check="meterCheckChange"
                   @node-click="nodeClick">
            <div slot-scope="scope" class="el-tree-node__label" :title="scope.data.label">
              {{ scope.data.label }}
            </div>
          </el-tree>
        </div>
        <pagination-box :total="meterPagination.total" :currentPage="meterPagination.page" :pageSize="meterPagination.size" @current-change="getcmdashboard"></pagination-box>
      </div>
      <div slot="footer" class="meter-footer">
        <el-button @click="closeMeterDialog">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="confirmMeter">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
